import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import 'bootstrap/dist/css/bootstrap';
import 'bootstrap/dist/js/bootstrap';

import './xhr_flash_errors';
import 'access';

const application = Application.start()
const context = require.context("../common/controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
